import * as React from 'react';
import TwoColumnsTwoThirdsOneThird from '../layouts/TwoColumnsTwoThirdsOneThird';
import {graphql} from 'gatsby';

/**
 * @author ESTECO CSA Team <csa@esteco.com>
 */
interface SpeakerInstructionsPageProps {
    data: QueryResult
}

interface Deadline {
    date: string,
    description: string[],
    title: null
}

interface QueryResult {
    allFile:
        {
            nodes: {
                id: string
                relativePath: string
                childMarkdownRemark: {
                    frontmatter: {
                        title: string,
                    }
                    html: string
                }
            } []
        },
    allSpeakerInstructionsJson: {
        nodes:
            {
                title: string,
                deadlines: Deadline[]
            }[]
    }
}


const SpeakerInstructionsPage: React.FC<SpeakerInstructionsPageProps> = ({data}) => {
    const content = data.allFile.nodes[0].childMarkdownRemark;
    const title = content.frontmatter.title;
    const deadlines = data.allSpeakerInstructionsJson.nodes[0];
    return (
        <TwoColumnsTwoThirdsOneThird>
            <div>
                <h1 className="page-title">{title}</h1>
                <div className="soul-content  soul-content--m  soul-space-stack-bottom-l" dangerouslySetInnerHTML={{__html: content.html}}/>
            </div>
            <div className="soul-space-stack-top-l">
                <h2 className="soul-space-stack-top-3-xl soul-font-size-xl h-text-bold">{deadlines.title}</h2>
                <div className="soul-space-stack-bottom-xl"/>

                {deadlines.deadlines.map((deadline: Deadline, firstIndex: number) => (
                    <div>
                        <div className="h-color-primary-bold soul-font-size-m  soul-space-stack-bottom-s">{deadline.date}</div>
                        <div className="soul-content soul-content--m soul-space-stack-bottom-xl">
                            {deadline.title ? (
                                <span><b>{deadline.title}</b><br/></span>
                            ) : ''}
                            {deadline.description.map((one: string, secondIndex: number) => (
                                <span key={`${firstIndex}-${secondIndex}-${one}`}>{one}<br/></span>))}
                        </div>
                    </div>
                ))}

            </div>
        </TwoColumnsTwoThirdsOneThird>
    );
};

export const query = graphql`
    query query  {
        allFile(filter: {relativePath: {eq: "speaker-instructions.md"}}) {
            nodes {
                relativePath
                id
                childMarkdownRemark {
                    html
                    frontmatter {
                        title
                    }
                }
            }
        }
        allSpeakerInstructionsJson {
            nodes {
                title
                deadlines {
                    date
                    description
                }
            }
        }
    }
`;

export default SpeakerInstructionsPage;
